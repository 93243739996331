import React from 'react';
import { useList, useToggle, useCounter, useAsync, } from 'react-use';
import { fromPairs, keyBy, } from 'lodash';
import { startOfMonth, startOfDay, endOfDay, addDays, } from 'date-fns';
import { collection, query, where, orderBy as dbOrderBy, limit, getCountFromServer, } from 'firebase/firestore';
import numeral from 'numeral';

import firebase, { functions } from '../../firebase';
import Page from '../hocs/Page';

const today = startOfDay(new Date());
const { keys, entries } = Object;
const db = firebase.firestore();
const dateRanges = {
  prevDate: { label: '前日', start: addDays(today, -1), end: addDays(today, -1), },
  thisDate: { label: '当日', start: today, end: null, },
  thisMonth: { label: '当月', start: startOfMonth(today), end: null, },
  all: { label: '全期間', start: null, end: null, },
};

export default Page(function Dashboard (props) {
  const { tenant } = props;
  const { value: counts, } = useAsync(async () => {
    const counts = fromPairs(await Promise.all(entries(dateRanges).map(async ([dateRangeName, { start, end }]) => {
      return [dateRangeName, (await getCountFromServer(query(collection(db, 'orders'), where('tenantId', '==', tenant.id), ...[(start && where('createdAt', '>=', startOfDay(start))), (end && where('createdAt', '<=', endOfDay(end)))].filter(_ => _)))).data().count];
    })));
    return counts;
  }, []);
  console.log(3, counts);

  return (
    <div className="dashboard container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>ダッシュボード</h4>
        </div>
        <div>
          <table className="table table-bordered">
            <thead className="thead-light text-center">
              <tr className="text-nowrap">
                <th style={{ minWidth: 200 }}></th>
                {
                  Object.values(dateRanges).map(({ label }) => {
                    return (
                      <th key={label} style={{ minWidth: 200 }}>
                        {label}
                      </th>
                    );
                  })
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  注文件数
                </th>
                {
                  entries(counts || {}).map(([dateRangeName, count]) => {
                    return (
                      <td key={dateRangeName} className="text-right">
                        {numeral(count).format()}
                      </td>
                    );
                  })
                }
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
