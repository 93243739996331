import React, { useRef, useEffect, useState, useMemo, Fragment, } from 'react';
import { Button, Input, FormGroup, Label, } from 'reactstrap';
import { collection, query, where, getCountFromServer, } from 'firebase/firestore';
import { useAsync, useList, useToggle } from 'react-use';
import { groupBy, uniq, isEmpty, pick, get, orderBy, keyBy, difference, } from 'lodash';
import { format as formatDate, addMinutes, } from 'date-fns';
import classnames from 'classnames';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import dedent from 'dedent';

import { yen } from '../../util';
import { fieldsToImportCost, exhibitingStatus, computeProductValues, } from '../../shared/models/product';
import firebase, { functions } from '../../firebase';
import { getDocumentData, getCollectionData, getAllCollectionDataByChunk } from '../../shared/firebase';
import { productStatuses, malls, } from '../../shared/config';
import { unslash, } from '../../shared/util';
import { basicSettingsStatus, netPriceSettingFields, } from '../../shared/models/tenant';
import { autoAdditionExclusionSkuFields, } from '../../shared/models/tenantSettings';
import { pricePerVariationStatus, } from '../../shared/models/parentVariation';
import ProductPriceEditor from '../ProductPriceEditor';
import ProductNumeralFieldEditor from '../ProductNumeralFieldEditor';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useReadMore from '../hooks/useReadMore';
import AmazonProductsModal from '../modals/AmazonProductsModal';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import ModalButton from '../ModalButton';
import Page from '../hocs/Page';
import Pagination from '../Pagination';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';
import QueryBoolean from '../QueryBoolean';

const countPerPage = 200;
const db = firebase.firestore();
const ordersRef = db.collection('orders');
const stopMallItems = functions.httpsCallable('stopMallItems');
const { entries, values } = Object;
const { round } = Math;
const statusOptions = entries(pick(productStatuses, ['exhibiting', 'processing', 'rejected', 'initial'])).map(([k, v]) => ({ value: k, label: v.label }));

export default Page(function Products (props) {
  const { user, tenant } = props;
  const queryParams = useQueryParams();
  const {
    onlyDeficit,
    onlyNoFbaShipmentFee,
    onlyNoCost,
  } = queryParams;
  const { count: productsCount, readMore } = useReadMore({ countPerPage, });
  const _basicSettingsStatus = basicSettingsStatus(tenant);
  const [selectedItems, { set: setSelectedItems, push: selectItem, removeAt: removeSelectedItemAt }] = useList([]);
  const [processingItems, { set: setProcessingItems, }] = useList([]);
  const unselectItem = _ => removeSelectedItemAt(selectedItems.indexOf(_));
  const isSelecting = selectedItems.length > 0;
  const productsRef = tenant.ref.collection('products');
  const parentVariationsRef = tenant.ref.collection('parentVariations');
  const unfinishedOrders = useCollectionSubscription(ordersRef.where('tenantId', '==', tenant.id).where('status', '==', 'initial').where('amazonStatus', 'in', ['RECEIVED', 'PLANNING', 'PROCESSING', 'FAILED']), [tenant.id]);
  const unfinishedProductIds = uniq(unfinishedOrders.map(_ => _.itemIds || []).flat());
  const [showsAmanzonProductsModal, toggleAmazonProductsModal] = useToggle();
  const productsBySkuForImportRef = useRef(null);
  const [sort, setSort] = useState({ field: 'updatedAt', iteratee: _ => _.updatedAt.toDate(), direction: 'desc', });
  const parentVariations = useCollectionSubscription(parentVariationsRef, [tenant.id]);
  const parentVariationsById = keyBy(parentVariations, 'id');
  const fbaSnapshots = useDocumentSubscription(tenant.ref.collection('metas').doc('fbaSnapshots'));
  const autoAdditionExclusionSkuSettingsRef = tenant.ref.collection('tenantSettings').doc('autoAdditionExclusionSku');
  const autoAdditionExclusionSkuSettings = useDocumentSubscription(autoAdditionExclusionSkuSettingsRef);

  const filteredProductsRef = useMemo(() => {
    let filteredProductsRef = productsRef;
    if(!isEmpty(queryParams.sku)) {
      filteredProductsRef = filteredProductsRef.where('sku', '==', queryParams.sku);
    }
    if(!isEmpty(queryParams.asin)) {
      filteredProductsRef = filteredProductsRef.where('amazonProduct.Identifiers.MarketplaceASIN.ASIN', '==', queryParams.asin);
    }
    if(!isEmpty(queryParams.parentAsin)) {
      filteredProductsRef = filteredProductsRef.where('amazonProduct.Relationships.VariationParent.Identifiers.MarketplaceASIN.ASIN', '==', queryParams.parentAsin);
    }
    if(!isEmpty(queryParams.title)) {
      filteredProductsRef = filteredProductsRef.where('amazonProduct.AttributeSets.ItemAttributes.Title', '>=', queryParams.title).where('amazonProduct.AttributeSets.ItemAttributes.Title', '<=', queryParams.title + '\uf8ff');
    } else {
      filteredProductsRef = filteredProductsRef.orderBy('updatedAt', 'desc');
    }
    return filteredProductsRef;
  }, [...['sku', 'asin', 'parentAsin', 'title'].map(_ => queryParams[_])]);

  const products = useCollectionSubscription(filteredProductsRef.limit(productsCount), [filteredProductsRef, productsCount], { initialItems: null, disablesReset: true });
  const { value: allProductsCount, } = useAsync(async () => {
    const { count } = (await getCountFromServer(query(collection(tenant.ref, 'products')))).data();
    return count;
  }, []);
  const productShopStatuses = useCollectionsFetch(products?.map(_ => db.collection('productShopStatuses').where('productId', '==', _.id)), [products], { initialItems: null, disablesReset: true, });
  const productShopStatusesGroupedByProductId = useMemo(_ => productShopStatuses && groupBy(productShopStatuses, 'productId'), [productShopStatuses]);
  const productsBySku = keyBy(products, 'sku');
  const generateRowItems = (products) => {
    return products.map((product) => {
      const { id, ref, sku, updatedAt, netPrice, amazonProduct, quantity, manualFbaShipmentFee, } = product;
      const variationParentAsin = get(product.amazonProduct, ['Relationships', 'VariationParent', 'Identifiers', 'MarketplaceASIN', 'ASIN']);
      const parentVariation = parentVariationsById[variationParentAsin];
      const isVariationsProduct = variationParentAsin && !get(parentVariation, 'pricePerVariationEnabled');
      const title = amazonProduct?.AttributeSets?.ItemAttributes?.Title;
      const imageUrl = amazonProduct?.AttributeSets?.ItemAttributes?.SmallImage?.URL;
      const asin = amazonProduct?.Identifiers?.MarketplaceASIN?.ASIN;
      const amount = amazonProduct?.Offers?.Offer?.BuyingPrice?.ListingPrice?.Amount;
      const url = `https://www.amazon.co.jp/gp/offer-listing/${asin}`;
      const { fbaShipmentFee, conclusiveFbaShipmentFee, profit, profitRate, } = computeProductValues(product);
      return {
        ...product,
        title,
        imageUrl,
        asin,
        amount,
        url,
        fbaShipmentFee,
        conclusiveFbaShipmentFee,
        profit,
        profitRate,
        variationParentAsin,
        parentVariation,
        pricePerVariationStatus: pricePerVariationStatus(parentVariation),
        isVariationsProduct,
      };
    });
  };
  const rowItems = useMemo(_ => products && generateRowItems(products), [products, parentVariations]);
  const filterRowItems = (rowItems, productShopStatusesGroupedByProductId) => {
    let filteredRowItems = rowItems;
    if(!isEmpty(queryParams.statuses)) {
      filteredRowItems = filteredRowItems.filter(_ => queryParams.statuses.includes(exhibitingStatus(_, productShopStatusesGroupedByProductId)));
    }
    if(queryParams.onlyPresent === '1') {
      filteredRowItems = filteredRowItems.filter(_ => _.quantity > 0);
    }
    if(onlyDeficit === '1') {
      filteredRowItems = filteredRowItems.filter(_ => _.profit < 0);
    }
    if(onlyNoFbaShipmentFee === '1') {
      filteredRowItems = filteredRowItems.filter(_ => _.fbaShipmentFee == null);
    }
    if(onlyNoCost === '1') {
      filteredRowItems = filteredRowItems.filter(_ => _.cost == null || _.cost == '');
    }
    return filteredRowItems;
  };
  const filteredRowItems = useMemo(_ => rowItems && filterRowItems(rowItems, productShopStatusesGroupedByProductId), [rowItems, productShopStatusesGroupedByProductId, ...['statuses', 'onlyPresent', 'onlyDeficit', 'onlyNoFbaShipmentFee', 'onlyNoCost'].map(_ => queryParams[_])]);
  const displayRowItems = useMemo(_ => filteredRowItems || [], [filteredRowItems]);
  const onClickSort = ({ field, iteratee }) => {
    setSort({
      field,
      iteratee,
      direction: field === sort.field && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  };
  const onClickDelete = async (items, isBatch) => {
    if (!window.confirm(`本当に削除しますか？${isBatch ? '(未完了の注文がある商品については削除されません)' : ''}`)) return;

    setProcessingItems(items);
    try {
      await items.reduce(async (x, item) => {
        await x;

        const res = await stopMallItems({ tenantId: tenant.id, productId: item.id, });
        if(res.data.status === 'NG') throw pick(res.data, ['errorExpander', 'errorShop', 'errorMessage']);

        await item.ref.delete();
      }, Promise.resolve());
      toast.success('削除しました');
    } catch(error) {
      toast.error('失敗しました');
      if(user.admin && error.errorShop) {
        toast.error(`[${error.errorExpander?.name}] [${error.errorShop.name}] ${error.errorMessage}`);
      }
      console.error(error);
    }
    setProcessingItems([]);
    setSelectedItems([]);
  };
  const onClickAutoAdd = async (enabled) => {
    const confirmMessage = ({
      true: dedent`
        Amazonで出品中［在庫1以上］の商品を全てhanro+へ自動登録します。
        （Amazonで商品が新規追加、在庫追加された場合も自動登録されます。）
        よろしいですか？
      `,
      false: '本当に停止しますか？'
    })[enabled];
    if(!window.confirm(confirmMessage)) return;

    await tenant.ref.update({ autoProductAdditionEnabled: enabled });
  }
  const processRows = async (_rows) => {
    const rows = _rows.filter(_ => _.SKU);
    const products = (await Promise.all(uniq(rows.map(_ => _.SKU)).map(async (sku) => {
      return await getCollectionData(tenant.ref.collection('products').where('sku', '==', sku).limit(1));
    }))).flat();
    productsBySkuForImportRef.current = keyBy(products, 'sku');
    return rows;
  };
  const processRow = (batch, row, i) => {
    const { sku, cost, manualFbaShipmentFee, } = { sku: row.SKU, cost: numeral(row['仕入値']).value(), manualFbaShipmentFee: numeral(row['FBA配送料']).value(), };
    const product = productsBySkuForImportRef.current[sku];
    batch.update(product.ref, { cost, manualFbaShipmentFee, });
  };
  const costCsvSampleRows = () => {
    return [
      {
        SKU: 'SKU_1234',
        仕入値: 3000,
      },
    ];
  };
  const rowsForExport = async _ => {
    const products = await getAllCollectionDataByChunk(filteredProductsRef);
    const productShopStatuses = await getAllCollectionDataByChunk(db.collection('productShopStatuses').where('tenantId', '==', tenant.id));
    const productShopStatusesGroupedByProductId = groupBy(productShopStatuses, 'productId');
    const rowItems = generateRowItems(products);
    const filteredRowItems = filterRowItems(rowItems, productShopStatusesGroupedByProductId);
    return filteredRowItems.map((item) => {
      const { id, ref, title, imageUrl, asin, amount, url, conclusiveFbaShipmentFee, profit, profitRate, sku, updatedAt, netPrice, cost, amazonProduct, quantity, variationParentAsin, pricePerVariationStatus, } = item;
      return {
        ID: id,
        画像: imageUrl,
        親ASIN: variationParentAsin,
        ASIN: asin,
        SKU: sku,
        商品名: title,
        Amazon価格: amount,
        在庫数: quantity,
        卸値: netPrice,
        仕入値: cost,
        FBA配送料: conclusiveFbaShipmentFee,
        純利益: profit,
        利益率: profitRate,
        予想出品金額: round(netPrice * 1.3),
        更新日時: formatDate(updatedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        ステータス: get(productStatuses, `${exhibitingStatus(item, productShopStatusesGroupedByProductId)}.label`),
      };
    });
  };
  const costCsvFields = async () => {
    const products = await getAllCollectionDataByChunk(tenant.ref.collection('products'));
    const skus = products.map(_ => _.sku);
    return {
      sku: {
        validations: {
          required: v => !isEmpty(v),
          exists: v => skus.includes(v),
        },
      },
      cost: {
        validations: {
          greaterThanOrEqualTo0: v => v >= 0,
        },
      },
    };
  };
  useEffect(() => {
    if(autoAdditionExclusionSkuSettings === null) {
      autoAdditionExclusionSkuSettingsRef.set({ skus: '' });
    }
  }, [autoAdditionExclusionSkuSettings]);

  return(
    <div className="products container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>商品管理</h4>
        </div>
        <div className="text-danger mb-3">
          ※ 商品ページにのタイトル・説明分・写真等に変更があった場合はその都度カスタマーサポートまで該当SKUと変更点をご連絡くださいませ。
        </div>
        {
          tenant.autoProductAdditionEnabled && (
            <div className="alert alert-info mb-3">
              商品の自動登録が有効化されています。
            </div>
          )
        }
        {
          displayRowItems.some(_ => _.profit < 0) && (
            <div className="alert alert-warning mb-3">
              赤字の商品があります。
            </div>
          )
        }
        {
          displayRowItems.some(_ => _.conclusiveFbaShipmentFee == null) && (
            <div className="alert alert-warning mb-3">
              配送料未取得の商品があります。
            </div>
          )
        }
        <div className="small font-weight-bold mb-2">
          全商品: {numeral(allProductsCount).format()}件 ({numeral(displayRowItems.length).format()}件表示中)
        </div>
        <div className="d-flex justify-content-start align-items-end mb-2">
          <div className="d-flex flex-column gap-1">
            <div className="d-flex align-items-end flex-wrap gap-1">
              <QueryInput paramName="sku" label="SKUで検索" disabled={isSelecting} />
              <QueryInput paramName="asin" label="ASINで検索" disabled={isSelecting} />
              <QueryInput paramName="parentAsin" label="親ASINで検索" disabled={isSelecting} />
              <QueryInput paramName="title" label="商品名で検索(前方一致)" disabled={isSelecting} />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-end mb-2">
          {
            !isSelecting ? (
              <div className="d-flex align-items-end text-nowrap gap-1">
                <div className="border rounded p-2 px-3 d-flex align-items-center">
                  <div>
                    Amazon販売価格に対する卸値の割合: {tenant.netPriceRate || 90}%
                  </div>
                  <EditButton label={false} color="link" size="sm" className="ml-1" itemRef={tenant.ref} FormModal={ModelFormModal} formProps={{
                    title: '卸値のデフォルト設定',
                    fields: {
                      netPriceRate: {
                        ...netPriceSettingFields().netPriceRate,
                        hint: (
                          <div>
                            <div>
                              <span className="mr-1">※</span>
                              売上につながりやすい推奨値は90%以下になります。
                            </div>
                            <div className="d-flex">
                              <span className="mr-1">※</span>
                              hanro+への商品登録時のamazon販売価格の割合です。amazon販売価格を変更されましても、ハンロプラスの卸値は自動では変更されません。
                            </div>
                          </div>
                        ),
                      },
                    },
                  }}>
                  </EditButton>
                </div>
                {
                  _basicSettingsStatus === 'accepted' && (
                    <Fragment>
                      <Button color={tenant.autoProductAdditionEnabled ? 'warning' : 'success'} onClick={onClickAutoAdd.bind(null, !tenant.autoProductAdditionEnabled)}>
                        {
                          tenant.autoProductAdditionEnabled ? (
                            '商品の自動登録を停止する'
                          ) : (
                            '商品の自動登録を有効にする'
                          )
                        }
                      </Button>
                      <EditButton itemRef={autoAdditionExclusionSkuSettingsRef} label="自動登録除外SKUの設定" FormModal={ModelFormModal} formProps={{ title: '自動登録除外SKUの設定', fields: autoAdditionExclusionSkuFields(), }} />
                    </Fragment>
                  )
                }
                <div className="d-flex flex-column align-items-start">
                  <div className="text-muted small">仕入値・配送料</div>
                  <ImportButton label="インポート" processRows={processRows} processRow={processRow} documentName="product" fields={costCsvFields} fields={fieldsToImportCost({ productsBySkuRef: productsBySkuForImportRef, })} />
                </div>
                <ExportButton fileName="商品.csv" rows={rowsForExport} />
                <Button color="primary" onClick={toggleAmazonProductsModal}>
                  <span className="fas fa-plus mr-1" />
                  新規登録
                </Button>
              </div>
            ) : (
              <div className="d-flex justify-content-end mb-3 align-items-end">
                {selectedItems.length} 件を選択中
                <div className="ml-2">
                  <div className="text-muted small">卸値(円)</div>
                  <ProductPriceEditor tenant={tenant} value={''} items={selectedItems.filter(_ => exhibitingStatus(_, productShopStatusesGroupedByProductId) !== 'rejected')} onFinish={_ => setSelectedItems([])} user={user} />
                </div>
                <div className="ml-2">
                  <div className="text-muted small">Amazon販売価格に対する割合(％)</div>
                  <ProductPriceEditor type="rate" tenant={tenant} value={''} items={selectedItems.filter(_ => exhibitingStatus(_, productShopStatusesGroupedByProductId) !== 'rejected')} onFinish={_ => setSelectedItems([])} disabled={selectedItems.some(_ => _.amazonProduct?.Offers?.Offer?.BuyingPrice?.ListingPrice?.Amount == null)} user={user} />
                </div>
                <div className="ml-2">
                  <div className="text-muted small">利益率(％)</div>
                  <ProductPriceEditor type="profitRate" tenant={tenant} value={''} items={selectedItems.filter(_ => exhibitingStatus(_, productShopStatusesGroupedByProductId) !== 'rejected' && parseInt(_.cost, 10) > 0 && _.conclusiveFbaShipmentFee > 0)} onFinish={_ => setSelectedItems([])} disabled={selectedItems.some(_ => _.amazonProduct?.Offers?.Offer?.BuyingPrice?.ListingPrice?.Amount == null)} user={user} />
                </div>
                <Button className="ml-2" color="danger" onClick={onClickDelete.bind(null, selectedItems.filter(_ => !unfinishedProductIds.includes(_.id)), true)} disabled={processingItems.length > 0}>
                  <span className="fas fa-trash mr-1" />
                  削除
                </Button>
              </div>
            )
          }
        </div>
        <div className="d-flex justify-content-start align-items-end mb-2">
          <div className="d-flex flex-column gap-1">
            <div className="d-flex align-items-end flex-wrap gap-1">
              <QuerySelector isMulti width={200} paramName="statuses" options={statusOptions} label="ステータスで絞込み" disabled={isSelecting} />
              <QueryBoolean className="ml-1" paramName="onlyDeficit" label="赤字の商品のみ" defaultValue={'0'} />
              <QueryBoolean className="ml-1" paramName="onlyNoFbaShipmentFee" label="配送料未取得の商品のみ" defaultValue={'0'} />
              <QueryBoolean className="ml-1" paramName="onlyNoCost" label="仕入値未入力の商品のみ" defaultValue={'0'} />
              <QueryBoolean className="ml-1" paramName="onlyPresent" label="在庫ありのみ" defaultValue={'0'} />
            </div>
          </div>
        </div>
        <div className="mt-3 overflow-scroll position-relative" style={{ zIndex: 0 }}>
          {
            products?.length > 0 ? (
              <div>
                <table className="table sticky-table">
                  <thead className="thead-light text-center">
                    <tr className="text-nowrap">
                      <th>
                        <Input type="checkbox" className="position-relative m-0" checked={difference(displayRowItems, selectedItems).length === 0} onChange={_ => _.target.checked ? setSelectedItems(displayRowItems) : setSelectedItems([])} />
                      </th>
                      <th>画像</th>
                      <th>親ASIN</th>
                      <th>ASIN</th>
                      <th style={{ minWidth: 100 }}>
                        SKU
                        {/*<span onClick={onClickSort.bind(null, { field: 'id' })} className="btn btn-link p-0">
                          SKU
                          <span className={classnames('fas', sort.field === 'id' && { 'fa-sort-up': sort.direction === 'asc', 'fa-sort-down': sort.direction === 'desc' })} />
                        </span>*/}
                      </th>
                      <th style={{ minWidth: 200 }}>商品名</th>
                      <th>Amazon価格</th>
                      <th>在庫数</th>
                      <th>
                        <div>卸値</div>
                        <div>仕入値</div>
                      </th>
                      <th>
                        <div>FBA配送料</div>
                        <div>純利益</div>
                        <div>利益率</div>
                      </th>
                      <th>予想出品金額</th>
                      <th>
                        更新日時
                        {/*<span onClick={onClickSort.bind(null, { field: 'updatedAt', iteratee: _ => _.updatedAt.toDate() })} className="btn btn-link p-0">
                          更新日時
                          <span className={classnames('fas', sort.field === 'updatedAt' && { 'fa-sort-up': sort.direction === 'asc', 'fa-sort-down': sort.direction === 'desc' })} />
                        </span>*/}
                      </th>
                      <th>
                        ステータス
                        {/*<span onClick={onClickSort.bind(null, { field: 'status', iteratee: _ => get(productStatuses, `${exhibitingStatus(_, productShopStatusesGroupedByProductId)}.label`) })} className="btn btn-link p-0">
                          ステータス
                          <span className={classnames('fas', sort.field === 'status' && { 'fa-sort-up': sort.direction === 'asc', 'fa-sort-down': sort.direction === 'desc' })} />
                        </span>*/}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      displayRowItems.map((item) => {
                        const { id, ref, title, imageUrl, asin, amount, url, fbaShipmentFee, conclusiveFbaShipmentFee, profit, profitRate, sku, updatedAt, netPrice, amazonProduct, quantity, variationParentAsin, pricePerVariationStatus, } = item;
                        const isProcessing = processingItems.includes(item);
                        const onClickPricePerVariationButton = async () => {
                          if(!window.confirm('本当に申請しますか？')) return;

                          await tenant.ref.collection('parentVariations').doc(variationParentAsin).set({
                            pricePerVariationRequestedAt: new Date(),
                            productId: id,
                          }, { merge: true });
                          toast.success('バリエーションごとの卸値設定の申請をしました');
                        };

                        return (
                          <tr key={id} id={id}>
                            <td>
                              <Input type="checkbox" className="position-relative m-0" checked={selectedItems.includes(item)} onChange={_ => selectedItems.includes(item) ? unselectItem(item) : selectItem(item)} />
                              {
                                user.dev && (
                                  <a className="d-block" href={`https://console.firebase.google.com/u/0/project/hanro-plus/firestore/data/~2Ftenants~2F${tenant.id}~2Fproducts~2F${item.id}`} target="_blank">
                                    F
                                  </a>
                                )
                              }
                            </td>
                            <td>
                              <img src={imageUrl} style={{ maxWidth: 80 }} />
                            </td>
                            <td>
                              {
                                variationParentAsin && (
                                  <div>
                                    <div>{variationParentAsin}</div>
                                    <div>
                                      {
                                        ({
                                          initial: () => (
                                            exhibitingStatus(item, productShopStatusesGroupedByProductId) !== 'exhibiting' && (
                                              <Button size="sm" className="d-flex align-items-center" onClick={onClickPricePerVariationButton} style={{ minWidth: 180 }}>
                                                <span className="fas fa-paper-plane mr-2" />
                                                <div>
                                                  バリエーションごとの
                                                  <br />
                                                  卸値設定の申請
                                                </div>
                                              </Button>
                                            )
                                          ),
                                          requested: () => (
                                            <div className="text-secondary small">
                                              (バリエーションごとの卸値設定の申請済み)
                                            </div>
                                          ),
                                          enabled: () => (
                                            <div className="badge badge-success small">
                                              バリエーションごとの卸値設定可
                                            </div>
                                          ),
                                        })[pricePerVariationStatus]()
                                      }
                                    </div>
                                  </div>
                                )
                              }
                            </td>
                            <td className="text-nowrap">{asin}</td>
                            <td className="text-break">{sku}</td>
                            <td className="text-break">
                              <a href={url} target="_blank">
                                {title}
                              </a>
                            </td>
                            <td className="text-nowrap text-right">
                              {yen(parseInt(amount, 10))}
                            </td>
                            <td className="text-nowrap text-right">
                              {numeral(quantity).format('0,0')}
                            </td>
                            <td style={{ minWidth: 150, width: 150 }}>
                              <div className="d-flex justify-content-between gap-1">
                                <ModalButton size="sm" color="link" title="履歴" label={<span className="fas fa-history" />} content={_ => <NetPriceLogs product={item} />} />
                                <ProductPriceEditor className="flex-fill" tenant={tenant} value={netPrice} items={[item]} disabled={isSelecting || ['rejected'].includes(exhibitingStatus(item, productShopStatusesGroupedByProductId)) || isProcessing} user={user} />
                              </div>
                              <ProductNumeralFieldEditor className="mt-1" field="cost" tenant={tenant} product={item} />
                            </td>
                            <td style={{ minWidth: 150, width: 150 }} className="text-right">
                              <div>
                                {
                                  fbaShipmentFee != null ? (
                                    yen(fbaShipmentFee)
                                  ) : (
                                    <ProductNumeralFieldEditor className="mt-1" field="manualFbaShipmentFee" tenant={tenant} product={item} />
                                  )
                                }
                              </div>
                              <div>{yen(profit)}</div>
                              <div>{numeral(profitRate).format('0.0%')}</div>
                            </td>
                            <td className="text-right">
                              {yen(round(netPrice * 1.3))}
                            </td>
                            <td className="text-nowrap">
                              {formatDate(updatedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                            </td>
                            <td className="text-nowrap">
                              {
                                productShopStatusesGroupedByProductId && (
                                  <div>
                                    <div>
                                      {get(productStatuses, `${exhibitingStatus(item, productShopStatusesGroupedByProductId)}.label`)}
                                    </div>
                                    {
                                      exhibitingStatus(item, productShopStatusesGroupedByProductId) === 'rejected' && (
                                        <div className="mt-1 d-flex flex-column align-items-start">
                                        </div>
                                      )
                                    }
                                  </div>
                                )
                              }
                            </td>
                            <td className="text-right text-nowrap">
                              <Button color="danger" onClick={onClickDelete.bind(null, [item])} disabled={isSelecting || isProcessing || unfinishedProductIds.includes(id)}>
                                <span className="fas fa-trash mr-1" />
                                削除
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                {
                  products.length === productsCount && (
                    <div className="mt-3">
                      <Button onClick={readMore}>
                        もっと見る
                      </Button>
                    </div>
                  )
                }
              </div>
            ) : (
              <div>
                商品は未登録です
              </div>
            )
          }
        </div>
      </div>
      {showsAmanzonProductsModal && <AmazonProductsModal isOpen user={user} onClickClose={toggleAmazonProductsModal} tenant={tenant} />}
    </div>
  );
});

function NetPriceLogs (props) {
  const { product } = props;
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>日時</th>
          <th>保存者</th>
          <th>卸値</th>
        </tr>
      </thead>
      <tbody>
        {
          (product.netPriceLogs || []).map((netPriceLog, i) => {
            const { savedAt, savedBy, netPrice } = netPriceLog;
            return (
              <tr key={i}>
                <td>
                  {formatDate(savedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                </td>
                <td>
                  {savedBy.displayName}
                </td>
                <td className="text-right">
                  {numeral(netPrice).format()}
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}
